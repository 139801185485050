import Image, { StaticImageData } from 'next/image';
import styles from './ChallengeDropCard.module.scss';
import Link from 'next/link';
import { APP_URLS } from '@/configs/urls';
import { itemRarities } from '@/configs/itemRarities';
import BgPrize from '@/assets/icons/give/bgPrize';
import CircleRarity from '@/assets/icons/give/circleRarity/circleRarity';
import Members from '@/assets/icons/give/member.svg';
import CentreButton from '@/components/styled/CustomButtons/CentreButton/CentreButton';
import TimerBlock, { dateToUtc, getLabel } from '../CIrcle/TimerBlock';
import { useEffect, useState } from 'react';
import ParticipantInfo from '@/components/Give/ParticipantInfo/ParticipantInfo';
import { Winner } from '@/pages/give/[id]';
import ItemWinner from '../ItemWinner/ItemWinner';
import { useAuthContext } from '@/hooks/use-auth-context';
import { useGetGiveawayItemLazyQuery } from '@/generated/projectlb-hasura';
import { toast } from 'react-toastify';
import { getSkinName, getWeaponName } from '@/utils/item-helpers';
import { itemQualities } from '@/configs/itemQualities';
import OptimizedImage from '@/components/Image';
import PriceComponent from '@/components/Give/PriceComponent/PriceComponent';

interface IChallengeDropCard {
  weaponName: string | null | undefined;
  skinName: string | null | undefined;
  price: number | null | undefined;
  image: string | StaticImageData | null | undefined;
  rarity: string | null | undefined;
  start_date: string | null | undefined;
  currentDeposit?: number;
  minimalDeposit?: number;
  userParticipates?: boolean | null | undefined;
  winnerId?: string | null | undefined;
  winnerAvatar?: string;
  winnerName?: string;
  end_date: string | null | undefined;
  userCount?: number | null | undefined;
  winner?: Winner;
  giveawayId: string;
  itemClaimed?: boolean;
  handleClickTab?: any;
  quality: string;
  totalSecond?: number;
}

export function formatDate(dateString: string) {
  const date = new Date(dateString);
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  return `${day}.${month < 10 ? '0' : ''}${month}.${year % 100}`;
}

const ChallengeDropCard = (props: IChallengeDropCard) => {
  const {
    image,
    price,
    skinName,
    weaponName,
    rarity,
    userParticipates,
    end_date,
    start_date,
    userCount,
    winner,
    winnerId,
    giveawayId,
    itemClaimed,
    handleClickTab,
    quality,
    totalSecond,
  } = props;

  const startDate = new Date(start_date!).getTime();
  const endDate = new Date(end_date!).getTime();
  const [timeLeft, setTimeLeft] = useState(
    Math.max(0, Math.floor((endDate - dateToUtc()) / 1000))
  );
  const [isItemClaimed, setIsItemClaimed] = useState(itemClaimed);

  useEffect(() => {
    setIsItemClaimed(itemClaimed);
  }, [itemClaimed]);

  const { userId, accessToken } = useAuthContext();

  const [getGiveawayItem, { loading: giveawayItemLoading }] =
    useGetGiveawayItemLazyQuery();
  const handleButtonClick = () => {
    getGiveawayItem({
      context: { token: accessToken },
      fetchPolicy: 'no-cache',
      variables: {
        giveaway_id: giveawayId,
      },
      onCompleted(data) {
        const res = data.GiveawayGetItem;

        if (res?.success) {
          toast.success('Награда успешно получена');
          setIsItemClaimed(true);
        } else {
          toast.error(res?.error);
        }
      },
      onError() {
        toast.error('Произошла ошибка, попробуйте позже');
      },
    });
  };

  return (
    <div className={styles.container}>
      <div className={styles.container_head}>
        <PriceComponent price={price} />
        {getLabel(timeLeft, startDate) !== 'Завершено' && (
          <div className={styles.container_part_info}>
            <ParticipantInfo active={userParticipates!} />
          </div>
        )}
        {getLabel(timeLeft, startDate) === 'Завершено' && (
          <div className={styles.info_time}>
            {formatDate(start_date!)} - {formatDate(end_date!)}
          </div>
        )}
      </div>
      <div className={styles.container_image}>
        <div className={styles.prize_bg}>
          <BgPrize color={itemRarities[rarity!]} />
        </div>
        <div className={styles.circle_bg}>
          <CircleRarity color={itemRarities[rarity || '']} />
        </div>
        <div className={styles.image_wrapper}>
          <OptimizedImage
            src={image || ''}
            alt="Weapon image"
            width={180}
            height={124}
            quality={100}
          />
        </div>
      </div>
      <div className={styles.container_weapon_name}>
        <p className={styles.text_rarity}>{itemQualities[quality]}</p>
        <p
          className={styles.text_weapon_name}
          style={{ color: itemRarities[rarity!] }}
        >
          {getWeaponName(weaponName)}
        </p>
        <p title={skinName || undefined} className={styles.text_skin_name}>
          {getSkinName(skinName)}
        </p>
      </div>
      {startDate && endDate && getLabel(timeLeft, startDate) !== 'Завершено' ? (
        <TimerBlock startDate={startDate} endDate={endDate} />
      ) : null}
      {winner && (
        <ItemWinner
          winner={winner}
          giveawayId={giveawayId!}
          itemClaimed={itemClaimed!}
        />
      )}
      {getLabel(timeLeft, startDate) === 'до розыгрыша' && (
        <>
          <div className={styles.container_button}>
            <Link href={APP_URLS.getGiveawayPage(giveawayId!)}>
              {!userParticipates ? (
                <CentreButton text={'Участвовать'} />
              ) : (
                <CentreButton
                  text={'Подробнее'}
                  className={styles.button_info}
                />
              )}
            </Link>
          </div>
          <div className={styles.container_user_count}>
            <Image src={Members} />
            <p className={styles.user_count_text}>УЧАСТНИКОВ:</p>
            <p className={styles.user_count_number}>{userCount}</p>
          </div>
        </>
      )}
      {userParticipates &&
        getLabel(timeLeft, startDate) === 'Завершено' &&
        (winner?.winner_id === userId || winnerId === userId ? (
          <div className={styles.winnerBtn}>
            <CentreButton
              text={isItemClaimed ? 'Получено' : 'Забрать'}
              disabled={isItemClaimed}
              loading={giveawayItemLoading}
              variant="outlined"
              className={styles.reward_button}
              onClick={handleButtonClick}
            />
          </div>
        ) : (
          <div className={styles.bottom_card_info}>
            <div className={styles.bottom_card_text}>
              <h4>В этот раз вы ничего не выиграли</h4>
              <p>Попробуйте выиграть в другой раздаче</p>
            </div>
            <CentreButton
              text={'Попробовать'}
              className={styles.bottom_card_btn}
              onClick={() => handleClickTab('active')}
            />
          </div>
        ))}
    </div>
  );
};

export default ChallengeDropCard;
