import { APP_URLS } from '@/configs/urls';
import { isCount } from '@/utils/check-count';
import { normalise } from '@/utils/normalise';
import Link from 'next/link';
import { CustomProgress } from '../../styled/CustomProgress';
import styles from './Case.module.scss';
import CentreButton from '@/components/styled/CustomButtons/CentreButton/CentreButton';
import { FC } from 'react';
import OptimizedImage from '@/components/Image';
import { useSoundContext } from '../../../sounds';
import { track } from '@amplitude/analytics-browser';
import { useAuthContext } from '@/hooks/use-auth-context';
import posthog from 'posthog-js';

type NullOrUndefined = null | undefined;

interface CaseProps {
  id: number;
  section: string;
  badge?: string | null;
  imageLink: string | NullOrUndefined;
  title: string | NullOrUndefined;
  price: number | NullOrUndefined;
  openCount?: number | NullOrUndefined;
  openMaxCount?: number | NullOrUndefined;
  color: string;
  slug: string | NullOrUndefined;
}

const badgeConfig: Record<string, { color: string; text: string }> = {
  cashback: {
    color: '#18FEF0',
    text: 'cashback',
  },
};

/**
 * Компонент одного кейса
 */
export const Case: FC<CaseProps> = (props) => {
  const { isAuthorized } = useAuthContext();
  const { playSound } = useSoundContext();
  const handleClick = () => {
    playSound('click_general');
    if (props.section === 'Гиперрр кейсы' || props.section === 'Дающие кейсы')
      track('dynamic_section', {
        auser: isAuthorized,
        subevent:
          props.section === 'Гиперрр кейсы' ? 'hyper_cases' : 'giving_cases',
        case: props.title,
      });
      posthog.capture('dynamic_section', {
        subevent:  props.section === 'Гиперрр кейсы' ? 'hyper_cases' : 'giving_cases',
        auser: isAuthorized,
        case: props.title,
      });
  };

  return (
    <Link href={APP_URLS.getCasePage(props.slug!)} passHref>
      <a className={styles.container} onClick={handleClick}>
        <div className={styles.img}>
          {props.badge && (
            <div
              className={styles.badge}
              style={{ background: badgeConfig[props.badge].color }}
            >
              <p className={styles.badge_text}>
                {badgeConfig[props.badge].text}
              </p>
            </div>
          )}
          <OptimizedImage
            src={props.imageLink ?? 'TODO: добавить картинку-плейсхолдер кейса'}
            alt={`${props.title}`}
            className={styles.image}
            layout="fill"
            objectFit="contain"
          />
          <div className={styles.gradientWrapper}>
            <div
              className={styles.gradient}
              style={{ background: props.color }}
            />
          </div>
        </div>

        <p className={styles.text}>{props.title}</p>

        <div className={styles.buttonWithTextContainer}>
          <CentreButton
            variant="outlined"
            color="mainGreen"
            className={styles.buttonWithTextContainer__button}
            text={`${props.price}`}
            wallet
          />

          {/*Контейнеры из ограниченной серии*/}
          {isCount(props.openCount) && isCount(props.openMaxCount) && (
            <div className={styles.countContainer}>
              <p className={styles.countContainer__countText}>
                {props.openCount.toLocaleString('ru-RU')} /{' '}
                {props.openMaxCount.toLocaleString('ru-RU')}
              </p>
              <CustomProgress
                barcolor="#DA47FD"
                variant="determinate"
                value={normalise(props.openCount, props.openMaxCount)}
              />
            </div>
          )}
        </div>
      </a>
    </Link>
  );
};
